import { Currency } from '@rediredi/types'

export interface FormatPriceOptions extends Intl.NumberFormatOptions {
  locale?: string
  currency?: string
}

export const CURRENCY_OPTIONS_MAP: Record<string, object> = {
  [Currency.COP]: {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  },
  [Currency.PYG]: {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  },
  [Currency.ARS]: {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  },
  [Currency.UYU]: {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  },
  [Currency.CLP]: {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  },
}

// Some currency symbols only are displayed correctly by using their locale
const CURRENCY_LOCALE_MAP: Record<string, string> = {
  PEN: 'es-PE',
  COP: 'es-CO',
  USD: 'en-US',
  MXN: 'es-MX',
  PYG: 'es-PY',
  ARS: 'es-AR',
  UYU: 'es-UY',
  CLP: 'es-CL',
}

const CUSTOM_CURRENCY: Record<string, string> = {
  COP: '$',
  ARS: '$',
  UYU: '$',
  CLP: '$',
}

const baseOptions = {
  locale: 'pt-BR',
  notation: 'standard',
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
  currencyDisplay: 'narrowSymbol',
}

const getLocale = (options?: FormatPriceOptions) => {
  return (
    (options?.currency && CURRENCY_LOCALE_MAP[options?.currency]) ||
    options?.locale ||
    baseOptions.locale
  )
}

export const formatPrice = (price: number, options?: FormatPriceOptions) => {
  const currencyOptions = CURRENCY_OPTIONS_MAP[options?.currency as Currency] || {}
  const defaultOptions = {
    ...baseOptions,
    ...options,
    locale: getLocale(options),
    currency: options?.currency || Currency.BRL,
  } as FormatPriceOptions
  return new Intl.NumberFormat(defaultOptions.locale, {
    style: 'currency',
    ...defaultOptions,
    ...currencyOptions,
  })
    .formatToParts(price)
    .map(({ type, value }) => {
      switch (type) {
        case 'currency':
          if (CUSTOM_CURRENCY[options?.currency || '']) {
            return CUSTOM_CURRENCY[options?.currency || '']
          }
          return value
        default:
          return value
      }
    })
    .reduce((str, pt) => str + pt)
}

export const formatPriceInParts = (price: number, options?: FormatPriceOptions) => {
  let currency = ''
  let integer = ''
  let decimal = ''
  let fraction = ''

  const currencyOptions = CURRENCY_OPTIONS_MAP[options?.currency as Currency] || {}

  const defaultOptions = {
    ...baseOptions,
    ...options,
    locale: getLocale(options),
    currency: options?.currency || Currency.BRL,
  } as FormatPriceOptions

  new Intl.NumberFormat(defaultOptions.locale, {
    style: 'currency',
    ...defaultOptions,
    ...currencyOptions,
  })
    .formatToParts(price)
    .forEach((p) => {
      if (p.type === 'currency') {
        if (CUSTOM_CURRENCY[options?.currency || '']) {
          currency = CUSTOM_CURRENCY[options?.currency || '']
          return
        }

        currency = p.value
      }

      if (p.type === 'integer' || p.type === 'group') {
        integer += p.value
      }

      if (p.type === 'decimal') {
        decimal = p.value
      }

      if (p.type === 'fraction') {
        fraction = p.value
      }
    })

  return { currency, amount: `${integer}${decimal}${fraction}` }
}
